import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./BrandSection.module.scss";

const BrandSection = props => {
  const images = useStaticQuery(graphql`
    {
      sharperImage: file(
        relativePath: { eq: "video-ad-customers/logo-sharper-img@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 232, layout: FIXED)
        }
      }
      drT: file(relativePath: { eq: "video-ad-customers/dr-t@3x.png" }) {
        childImageSharp {
          gatsbyImageData(width: 84, layout: FIXED)
        }
      }
      fourSigmatic: file(
        relativePath: { eq: "video-ad-customers/fs-logo@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 130, layout: FIXED)
        }
      }
      beeKeepers: file(
        relativePath: { eq: "video-ad-customers/beekeepers@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 52, layout: FIXED)
        }
      }
      hvmn: file(relativePath: { eq: "video-ad-customers/hvmn-logo@3x.png" }) {
        childImageSharp {
          gatsbyImageData(width: 169, layout: FIXED)
        }
      }
    }
  `);

  return (
    <section className={styles.brandSection}>
      <div className="container">
        <h5>{props.topBrandsText}</h5>
        <div className={styles.customerBar}>
          {Object.keys(images).map((key, idx) => {
            const image = images[key];

            return (
              <div>
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  key={idx}
                  alt={`customer ${idx}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BrandSection;
