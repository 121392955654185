import React from "react";
import { animated, useSpring } from "react-spring";
import ColorIcon from "../../../assets/video-floating-icons/color.svg";
import EditIcon from "../../../assets/video-floating-icons/edit.svg";
import ImageIcon from "../../../assets/video-floating-icons/image.svg";
import NameIcon from "../../../assets/video-floating-icons/name.svg";
import TemplateIcon from "../../../assets/video-floating-icons/template.svg";
import VideoIcon from "../../../assets/video-floating-icons/video.svg";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { navigateToSignupFromSBv } from "../utils";
import FloatingIcon from "./FloatingIcon";
import * as styles from "./HeroSection.module.scss";
import { useSegment } from "../../../utils/analytics";
import { CTA } from "../../../constants/text";
import { URL } from "../../../constants/destinations";
import {
  Name,
  Type,
  Location,
  Category
} from "../../../utils/analytics/constants";
import { isBrowser } from "../../../utils/browser";
import { nav } from "../../../utils/navigation";
import cn from "classnames";
import HeroCtas from "../../HeroCtas";

interface HeroSectionProps {
  h1Title: string;
  title?: any;
  poweredBy?: string;
  getStartedText?: string;
  cta?: any;
  secondaryCta?: any;
  locale?: string;
  page?: string;
}

const calc = (x, y) => [
  x - window?.innerWidth / 2,
  y - window?.innerHeight / 2
];

const iconTranslateSmall = (x, y) => `translate3d(${x / 50}px,${y / 30}px,0)`;
const iconTranslateMedium = (x, y) => `translate3d(${x / 18}px,${y / 25}px,0)`;
const iconTranslateBig = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;

const HeroSection: React.FC<HeroSectionProps> = props => {
  const { title, poweredBy, getStartedText, cta, locale, secondaryCta, page } = props;
  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 300, friction: 140 }
  }));

  const { helpers } = useSegment();

  return (
    <section
      className={cn(styles.heroSection, "container")}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: isBrowser() && calc(x, y) })
      }
    >
      <h1
        className={cn(
          "m-auto text-[28px] md:text-[62px] lg:text-[82px]",
          locale == "de" ? "lg:text-[71px]" : ""
        )}
      >
        {title[0]}
        <br />
        {title[1]}
        <b>
          <i> {title[2]}</i>
        </b>
      </h1>
      <h4 className="m-0">{poweredBy}</h4>
      <h4>{getStartedText}</h4>
      <div className={styles.buttonBar}>
        <HeroCtas page={page} cta={cta} secondaryCta={secondaryCta} />
      </div>
      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateMedium)
        }}
      >
        <FloatingIcon icon={<TemplateIcon />} top={-96} left={98} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateBig)
        }}
      >
        <FloatingIcon icon={<ColorIcon />} top={-318} left={0} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateSmall)
        }}
      >
        <FloatingIcon icon={<VideoIcon />} top={-484} left={244} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateSmall)
        }}
      >
        <FloatingIcon icon={<EditIcon />} top={-56} right={120} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateMedium)
        }}
      >
        <FloatingIcon icon={<NameIcon />} top={-258} right={0} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateBig)
        }}
      >
        <FloatingIcon icon={<ImageIcon />} top={-444} right={194} />
      </animated.div>
    </section>
  );
};

export default HeroSection;
