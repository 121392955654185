import React from "react";
import { animated, useSpring } from "react-spring";
import SoundtrackSelector from "../../../assets/video-ads-page/soundtrack-selector.svg";
import FrostierThumbnail from "../../../images/video-thumbnails/tn-frostier.png";
import { isBrowser } from "../../../utils/browser";
import FeatureSplitContainer from "../FeatureSplitContainer";
import { navigateToSignupFromSBv } from "../utils";
import * as styles from "./CreateSection.module.scss";
import * as featurestyles from "../../VideoAdsPage/FeatureItem/FeatureItem.module.scss";
import cn from "classnames";

interface CreateProps {
  title?: string;
  description?: string;
  features?: any;
  cta?: any;
  colorSelector?: any;
  video?: any;
  locale?: string;
}

const calc = (x, y) => [
  x - window?.innerWidth / 2,
  y - window?.innerHeight / 2
];
const musicTranslate = (x, y) => `translate3d(${x / 40}px,${y / 30}px,0)`;
const colorTranslate = (x, y) => `translate3d(${x / 80}px,${y / 30}px,0)`;

const CreateSection: React.FC<CreateProps> = props => {
  const { title, description, features, cta, colorSelector, video, locale } =
    props;
  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 300, friction: 140 }
  }));

  const creativeParallax = (
    <div className={styles.creative}>
      <div className={styles.videoPlayerContainer}>
        <video
          id={"frostier-video-player"}
          muted
          controls={false}
          loop
          preload="metadata"
          playsInline
          autoPlay
          crossOrigin="anonymous"
          poster={FrostierThumbnail}
        >
          <source src={video} type="video/mp4" />
          <img src={FrostierThumbnail} alt="frostier-template" />
        </video>
      </div>

      <animated.div
        style={{ transform: springProps.xy.interpolate(musicTranslate) }}
        className={styles.soundtrackSelector}
      >
        <SoundtrackSelector />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(colorTranslate)
        }}
        className={styles.colorSelector}
      >
        <img src={colorSelector} alt="" />
      </animated.div>
    </div>
  );
  const createFeaturesList = (
    <div
      className={cn(
        styles.createFeatureContainer,
        locale != "en-US" ? "flex-col" : "flex-col md:flex-row"
      )}
    >
      {features &&
        features?.map(feature => {
          return (
            <div className={cn(featurestyles?.featureItem, "md:mr-[20px]")}>
              <div className={featurestyles?.featureItemIconCircle}>
                <img width="18" height="18" src={feature?.svg?.url} alt="" />
              </div>
              <div className={featurestyles?.featureItemText}>
                {feature?.text}
              </div>
            </div>
          );
        })}
    </div>
  );

  return (
    <section
      role="presentation"
      className={styles.createSection}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: isBrowser() && calc(x, y) })
      }
    >
      <FeatureSplitContainer
        buttonId="features-video-create"
        title={title}
        body={description}
        creative={creativeParallax}
        reversed
        customBody={createFeaturesList}
        ctaText={cta?.text}
        ctaOnClick={navigateToSignupFromSBv}
      />
    </section>
  );
};

export default CreateSection;
