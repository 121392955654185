import React from "react";
import { animated, useSpring } from "react-spring";
import { isBrowser } from "../../../utils/browser";
import FeatureSplitContainer from "../FeatureSplitContainer";
import { navigateToSignupFromSBv } from "../utils";
import * as styles from "./OptimizeSection.module.scss";
import * as featurestyles from "../../VideoAdsPage/FeatureItem/FeatureItem.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";

interface OptimizeProps {
  title?: string;
  description?: string;
  features?: any;
  cta?: any;
  image?: any;
  imageGoals?: any;
  locale?: string;
}

const calc = (x, y) => [
  x - window?.innerWidth / 2,
  y - window?.innerHeight / 2
];
const goalRowTranslate = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`;

const OptimizeSection: React.FC<OptimizeProps> = props => {
  const { title, description, features, cta, image, imageGoals, locale } =
    props;

  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const creative = (
    <div className={styles.creative}>
      <img src={image} alt="" />
      <animated.div
        className={styles.goalRow}
        style={{ transform: springProps.xy.interpolate(goalRowTranslate) }}
      >
        <img src={imageGoals} alt="" />
      </animated.div>
    </div>
  );

  const featuresList = (
    <div
      className={cn(
        styles.optimizeFeaturesContainer,
        locale != "en-US" ? "flex-col" : "flex-col md:flex-row"
      )}
    >
      {features?.map(feature => {
        return (
          <div className={cn(featurestyles?.featureItem, "md:mr-[10px]")}>
            <div className={featurestyles?.featureItemIconCircle}>
              <img width="18" height="18" src={feature?.svg?.url} alt="" />
            </div>
            <div className={featurestyles?.featureItemText}>
              {feature?.text}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <section
      aria-hidden
      role="presentation"
      className={styles.optimizeSection}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: isBrowser() && calc(x, y) })
      }
    >
      <FeatureSplitContainer
        buttonId="features-video-optimize"
        title={title}
        body={description}
        creative={creative}
        reversed
        customBody={featuresList}
        ctaText={cta?.text}
        ctaOnClick={navigateToSignupFromSBv}
      />
    </section>
  );
};

export default OptimizeSection;
