import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import SEO from "../components/Seo";
import BrandSection from "../components/VideoAdsPage/BrandSection";
import CreateSection from "../components/VideoAdsPage/CreateSection";
import HeroSection from "../components/VideoAdsPage/HeroSection";
import LaunchSection from "../components/VideoAdsPage/LaunchSection";
import OptimizeSection from "../components/VideoAdsPage/OptimizeSection";
import PricingAndCtaSection from "../components/VideoAdsPage/PricingAndCtaSection";
import "../components/VideoAdsPage/video-ads-page.scss";
import VideoSection from "../components/VideoAdsPage/VideoSection";
import Layout from "../containers/Layout";
import { CTA_ID } from "../utils/analytics/constants";
import { PAGE } from "../constants/pages";

interface VideoPageProps {
  data: any;
}

const VideoPage: React.FC<VideoPageProps> = ({ data }) => {
  const pageData = data?.allContentfulSponsoredBrandsVideoPage?.nodes[0];

  const { language } = useI18next();

  const blueCard = {
    title: pageData?.getStartedCardTitle,
    description: pageData?.getStartedCardDescription,
    cta: pageData?.getStartedCardCta
  };
  const greyCard = {
    title: pageData?.customThemesCardTitle,
    description: pageData?.customThemesCardDescription,
    cta: pageData?.customThemesCardCta
  };

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id} locale={language}>
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        robots={pageData?.seoSettings?.robots}
      />
      <div className="sponsored-brands-video">
        <NavigationBarSpacer />
        <NavigationBarSpacer />
        <HeroSection
          page={PAGE.VIDEO_ADS}
          h1Title={pageData?.h1Title}
          title={pageData?.heroMainTitle}
          poweredBy={pageData?.poweredBy}
          getStartedText={pageData?.getStartedText}
          cta={pageData?.heroCta}
          secondaryCta={pageData?.secondaryHeroCta}
          locale={language}
        />
        <VideoSection image={pageData?.heroImage?.gatsbyImageData} />
        <BrandSection topBrandsText={pageData?.topBrands} />
        <CreateSection
          title={pageData?.createSectionTitle}
          description={
            pageData?.createSectionDescription?.createSectionDescription
          }
          features={pageData?.createFeatures}
          cta={pageData?.createCta}
          colorSelector={pageData?.colorSelectorSvg?.url}
          video={pageData?.createSectionVideo?.url}
          locale={language}
        />
        <LaunchSection
          title={pageData?.launchSectionTitle}
          description={
            pageData?.launchSectionDescription?.launchSectionDescription
          }
          features={pageData?.launchFeatures}
          cta={pageData?.launchCta}
          image={pageData?.launchImage?.gatsbyImageData}
        />
        <OptimizeSection
          title={pageData?.optimizeSectionTitle}
          description={
            pageData?.optimizeSectionDescription?.optimizeSectionDescription
          }
          features={pageData?.optimizeFeatures}
          cta={pageData?.optimizeCta}
          image={pageData?.optimizeImage?.url}
          imageGoals={pageData?.optimizeImageGoalsRow?.url}
          locale={language}
        />
        <PricingAndCtaSection blueCard={blueCard} greyCard={greyCard} />
      </div>
    </Layout>
  );
};

export default VideoPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulSponsoredBrandsVideoPage(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            gatsbyImageData
            url
          }
          robots
        }
        h1Title
        heroMainTitle
        poweredBy
        getStartedText
        heroCta {
          text
          route
        }
        secondaryHeroCta {
          text
          route
        }
        heroImage {
          gatsbyImageData(width: 335, layout: FIXED)
          url
        }
        topBrands
        createSectionTitle
        createSectionDescription {
          createSectionDescription
        }
        createFeatures {
          text
          svg {
            gatsbyImageData
            url
          }
        }
        createCta {
          text
          route
        }
        colorSelectorSvg {
          gatsbyImageData
          url
        }
        createSectionVideo {
          url
        }
        launchSectionTitle
        launchSectionDescription {
          launchSectionDescription
        }
        launchFeatures {
          text
          svg {
            gatsbyImageData
            url
          }
        }
        launchCta {
          text
          route
        }
        launchImage {
          gatsbyImageData(width: 548, layout: CONSTRAINED)
          url
        }
        optimizeSectionTitle
        optimizeSectionDescription {
          optimizeSectionDescription
        }
        optimizeFeatures {
          text
          svg {
            gatsbyImageData
            url
          }
        }
        optimizeCta {
          text
          route
        }
        optimizeImage {
          gatsbyImageData
          url
        }
        optimizeImageGoalsRow {
          gatsbyImageData
          url
        }
        getStartedCardTitle
        getStartedCardDescription
        getStartedCardCta {
          text
          route
        }
        customThemesCardTitle
        customThemesCardDescription
        customThemesCardCta {
          text
          route
        }
      }
    }
  }
`;
