import className from "classnames/bind";
import PropTypes from "prop-types";
import React from "react";
import { useSegment } from "../../../utils/analytics";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import * as styles from "./FeatureSplitContainer.module.scss";
import { URL } from "../../../constants/destinations";
import {
  Name,
  Type,
  Location,
  Category
} from "../../../utils/analytics/constants";

const cx = className.bind(styles);

interface Props {
  buttonId?: string;
  title?: string;
  creative?: any;
  body?: string;
  instructions?: string;
  reversed?: boolean;
  customBody?: any;
  ctaText?: string;
  ctaOnClick?: () => void;
}

const FeatureSplitContainer: React.FC<Props> = props => {
  const {
    buttonId,
    title,
    body,
    creative,
    reversed,
    customBody,
    ctaText,
    ctaOnClick
  } = props;
  const showButton = ctaOnClick && ctaText;

  const { helpers } = useSegment();
  return (
    <div
      className={cx(styles.featureSplitContainer, "container", {
        reversed
      })}
    >
      <div className={styles.textContent}>
        <h2>{title}</h2>
        <p>{body}</p>
        {customBody && customBody}
        {showButton && (
          <Button
            id={buttonId}
            text={ctaText}
            onClick={() => {
              helpers
                .delayTrack(Name.Cta, {
                  category: Category.ButtonClick,
                  type: Type.Button,
                  text: ctaText,
                  page_url: window?.location.href,
                  destination: URL.Video,
                  location: Location.FeatureContainer
                })
                .then(() => {
                  ctaOnClick();
                });
            }}
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            className={styles.ctaButton}
          />
        )}
      </div>
      {creative}
    </div>
  );
};

export default FeatureSplitContainer;

FeatureSplitContainer.propTypes = {
  buttonId: PropTypes.string,
  reversed: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  creative: PropTypes.element.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.element)
};
