import { nav } from "../../utils/navigation";

const createVideoPath = encodeURI(
  "https://app.perpetua.io/brands/add/video/?openvideocreator=true"
);
export const navigateToSignupFromSBv = () => {
  nav(`https://app.perpetua.io/signup/?redirect=${createVideoPath}`);
};

// Taken from "src/styles/_media-query"
export const SMALL_MOBILE_MAX_WIDTH = 500;
export const MOBILE_MAX_WIDTH = 640;
export const TABLET_MAX_WIDTH = 768;
export const DESKTOP_MAX_WIDTH = 1024;
export const LARGE_DESKTOP_MAX_WIDTH = 1200;
