import classNames from "classnames/bind";
import * as React from "react";
import { useSegment, trackView } from "../../../utils/analytics";
import { navigateToSignupFromSBv } from "../utils";
import CtaCard, { CtaCardType } from "./CtaCard";
import * as styles from "./PricingAndCtaSection.module.scss";
import { CTA } from "../../../constants/text";
import { URL } from "../../../constants/destinations";
import {
  Name,
  Type,
  Location,
  Category,
  CTA_ID
} from "../../../utils/analytics/constants";
import { nav } from "../../../utils/navigation";

interface CardsProps {
  blueCard?: any;
  greyCard?: any;
}

const cx = classNames.bind(styles);

const PricingAndCtaSection: React.FC<CardsProps> = props => {
  const { blueCard, greyCard } = props;
  const { segment, helpers } = useSegment();

  return (
    <section className={cx(styles.pricingAndCtaSection, "container")}>
      <CtaCard
        buttonId={CTA_ID.getStartedBottom.id}
        type={CtaCardType.primary}
        title={blueCard?.title}
        description={blueCard?.description}
        buttonCta={blueCard?.cta?.text}
        onClick={() => {
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: blueCard?.cta?.text,
              page_url: window?.location.href,
              destination: blueCard?.cta?.route,
              location: Location.BottomCta
            })
            .then(() => {
              nav(blueCard?.cta?.route);
            });
        }}
      />
      <CtaCard
        buttonId={CTA_ID.demoBottom.id}
        type={CtaCardType.secondary}
        title={greyCard?.title}
        description={greyCard?.description}
        buttonCta={greyCard?.cta?.text}
        onClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: greyCard?.cta?.text,
            page_url: window?.location.href,
            destination: greyCard?.cta?.route,
            location: Location.BottomCta
          });
          nav(greyCard?.cta?.route);
        }}
      />
    </section>
  );
};

export default PricingAndCtaSection;
