import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import TemplateCarousel from "./TemplateCarousel";
import * as styles from "./VideoSection.module.scss";

interface VideoProps {
  image?: any;
}

const VideoSection: React.FC<VideoProps> = props => {
  const { image } = props;

  return (
    <section className={cx(styles.videoSection)} id="video-section">
      <div className={styles.sbvPreviewContainer}>
        <GatsbyImage image={image} alt="" />
        <TemplateCarousel />
      </div>
    </section>
  );
};

export default VideoSection;
