import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import className from "classnames/bind";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Typing from "react-typing-animation";
import SearchIcon from "../../../../assets/video-ads-page/search.svg";
import BEEKEEPERSTemplate from "../../../../assets/video-templates/BEEKEEPERS_small.mp4";
import DrTobiasTemplate from "../../../../assets/video-templates/DR_TOBIAS_small.mp4";
import HVMNTemplate from "../../../../assets/video-templates/HVMN_small.mp4";
import PAVOITemplate from "../../../../assets/video-templates/PAVOI_small.mp4";
import SHARPERTemplate from "../../../../assets/video-templates/SHARPER_small.mp4";
import WILDETemplate from "../../../../assets/video-templates/WILDE_small.mp4";
import BEEKEEPERSThumbnail from "../../../../images/video-thumbnails/tn-beekeepers.png";
import DrTobiasThumbnail from "../../../../images/video-thumbnails/tn-drtobias.png";
import HVMNThumbnail from "../../../../images/video-thumbnails/tn-hvmn.png";
import PAVOIThumbnail from "../../../../images/video-thumbnails/tn-pavoi.png";
import SHARPERThumbnail from "../../../../images/video-thumbnails/tn-sharper.png";
import WILDEThumbnail from "../../../../images/video-thumbnails/tn-wilde.png";
import { isBrowser } from "../../../../utils/browser";
import FloatingButton, { Rotations } from "../../../FloatingButton";
import { TABLET_MAX_WIDTH } from "../../utils";
import "./carouselStylesOverride.css";
import * as styles from "./TemplateCarousel.module.scss";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

const cx = className.bind(styles);

const TemplateCarousel = () => {
  const { t } = useTranslation();

  const STARTING_TEMPLATES = [
    {
      video: DrTobiasTemplate,
      search: t("Colon Cleanse"),
      thumbnail: DrTobiasThumbnail
    },
    {
      video: SHARPERTemplate,
      search: t("Travel Iron"),
      thumbnail: SHARPERThumbnail
    },
    {
      video: HVMNTemplate,
      search: t("MCT Powder"),
      thumbnail: HVMNThumbnail
    },
    {
      video: BEEKEEPERSTemplate,
      search: t("Throat spray"),
      thumbnail: BEEKEEPERSThumbnail
    },
    {
      video: WILDETemplate,
      search: t("Natural Chips"),
      thumbnail: WILDEThumbnail
    },
    {
      video: PAVOITemplate,
      search: t("Gold Ear Cuffs"),
      thumbnail: PAVOIThumbnail
    }
  ];

  // State
  const currentIndexRef = useRef(
    Math.ceil((STARTING_TEMPLATES.length - 1) / 2)
  );
  const [slidesPerPage, setSlidesPerPage] = useState(3);
  const [selectedTemplate, setSelectedTemplate] = useState(
    STARTING_TEMPLATES[currentIndexRef.current]
  );

  const nextVideo = e => {
    // Sometimes the onChange is too fast and the next video player catches the "ended" event of the previous player
    // srcElement is only available when triggered by the ended event
    console.log("next video", e);
    if (
      e.srcElement &&
      e.srcElement.id !== getVideoPlayerName(currentIndexRef.current)
    ) {
      return;
    }
    if (currentIndexRef.current < STARTING_TEMPLATES.length - 1) {
      onChange(currentIndexRef.current + 1);
    } else {
      onChange(0);
    }
  };

  const prevVideo = e => {
    // Sometimes the onChange is too fast and the next video player catches the "ended" event of the previous player
    // srcElement is only available when triggered by the ended event
    if (
      e.srcElement &&
      e.srcElement.id !== getVideoPlayerName(currentIndexRef.current)
    ) {
      return;
    }
    if (currentIndexRef.current !== 0) {
      onChange(currentIndexRef.current - 1);
    } else {
      onChange(STARTING_TEMPLATES.length - 1);
    }
  };

  // Resize events so we can adjust number of videos on screen
  // Initial scroll handler call to pause/play video if it is visible
  // Initial ended event so it'll auto play the next video
  useEffect(() => {
    resizeHandler();
    scrollHandler();
    const currentPlayer = document.getElementById(
      getVideoPlayerName(currentIndexRef.current)
    );
    currentPlayer?.addEventListener("ended", nextVideo);
    isBrowser() && window?.addEventListener("resize", resizeHandler);
    return () =>
      isBrowser() && window?.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    const windowWidth = isBrowser() && window?.innerWidth;

    if (windowWidth < TABLET_MAX_WIDTH) {
      setSlidesPerPage(1);
    } else if (windowWidth > 1800) {
      setSlidesPerPage(5);
    } else {
      setSlidesPerPage(3);
    }
  };

  // Scroll events so we can pause & play the video
  useEffect(() => {
    isBrowser() && window?.addEventListener("scroll", scrollHandler);
    return () =>
      isBrowser() && window?.removeEventListener("scroll", scrollHandler);
  }, [selectedTemplate]);

  const scrollHandler = event => {
    const videoSection = document.getElementById("video-section");
    const videoOffsetTop = videoSection?.offsetTop;
    const videoSectionHeight = videoSection?.offsetHeight;

    const windowHeight = event
      ? event.currentTarget.innerHeight
      : isBrowser() && window?.innerHeight;
    const scrollPosition = event ? event.currentTarget.scrollY : 0;

    const currentVideoPlayer = document.getElementById(
      getVideoPlayerName(currentIndexRef.current)
    );

    if (
      videoOffsetTop &&
      videoSectionHeight &&
      scrollPosition + windowHeight > videoOffsetTop &&
      scrollPosition < videoOffsetTop + videoSectionHeight
    ) {
      if (currentVideoPlayer?.paused) {
        currentVideoPlayer?.play();
      }
    } else if (
      videoOffsetTop &&
      videoSectionHeight &&
      scrollPosition > videoOffsetTop + videoSectionHeight
    ) {
      if (!currentVideoPlayer?.paused) {
        currentVideoPlayer?.pause();
      }
    }
  };

  const onChange = newIndex => {
    const currentPlayer = document.getElementById(
      getVideoPlayerName(currentIndexRef.current)
    );
    currentPlayer?.removeEventListener("ended", nextVideo);
    currentPlayer?.pause();
    const newPlayer = document.getElementById(getVideoPlayerName(newIndex));
    newPlayer?.addEventListener("ended", nextVideo);
    newPlayer?.play();
    currentIndexRef.current = newIndex;
    setSelectedTemplate(STARTING_TEMPLATES[newIndex]);
  };

  return (
    <>
      <div className={styles.searchBarContainer}>
        <SearchBar text={selectedTemplate.search} />
      </div>

      <div className={styles.templateCarousel}>
        {isBrowser() && (
          <Carousel
            centered
            arrows
            draggable={false}
            slidesPerPage={slidesPerPage}
            onChange={onChange}
            arrowLeft={
              <FloatingButton
                rotation={Rotations.left}
                alt="Previous"
                onClick={prevVideo}
              />
            }
            arrowRight={
              <FloatingButton
                rotation={Rotations.right}
                alt="Next"
                onClick={nextVideo}
              />
            }
            value={currentIndexRef.current}
          >
            {STARTING_TEMPLATES.map((template, idx) => {
              const selectedVideo = currentIndexRef.current === idx;
              return (
                <VideoTemplate
                  key={idx}
                  index={idx}
                  video={template.video}
                  thumbnail={template.thumbnail}
                  selected={selectedVideo}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </>
  );
};

const getVideoPlayerName = index => `video-player-${index}`;

export default TemplateCarousel;

const VideoTemplate = props => {
  const { video, thumbnail, selected, index } = props;

  return (
    <div className={styles.templateContainer}>
      <video
        id={getVideoPlayerName(index)}
        className={cx(styles.templateVideo, { selected })}
        muted
        controls={selected}
        preload={selected ? "auto" : "none"}
        playsInline
        poster={thumbnail}
        crossOrigin="anonymous" // needed for safari
      >
        <source src={video} type="video/mp4" />
        <img src={thumbnail} alt={"template-example"} />
      </video>
    </div>
  );
};

VideoTemplate.propTypes = {
  video: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
};

const SearchBar = ({ text }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 0);
  }, [text]);

  return (
    <div className={styles.searchBar}>
      <SearchIcon className={styles.searchIcon} />
      {isVisible && <Typing>{text}</Typing>}
    </div>
  );
};
