import classNames from "classnames";
import React from "react";
import Back from "./assets/back.svg";
import * as styles from "./FloatingButton.module.scss";

export const Rotations = {
  up: styles.rotationUp,
  right: styles.rotationRight,
  down: styles.rotationDown,
  left: styles.rotationLeft
};

class FloatingButton extends React.Component {
  render() {
    const rotationName = this.props.rotation || Rotations.up;
    const className = classNames(
      styles.floatingButton,
      this.props.className,
      rotationName
    );
    const altText = this.props.altText || "to top";

    return (
      <button
        title={altText}
        className={className}
        onClick={this.props.onClick}
      >
        <Back />
      </button>
    );
  }
}

export default FloatingButton;
