import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { animated, useSpring } from "react-spring";
import Frostier from "../../../assets/video-ads-page/FROSTIER_small.mp4";
import FrostierThumbnail from "../../../images/video-thumbnails/tn-frostier.png";
import { isBrowser } from "../../../utils/browser";
import FeatureSplitContainer from "../FeatureSplitContainer";
import { navigateToSignupFromSBv } from "../utils";
import * as styles from "./LaunchSection.module.scss";
import * as featurestyles from "../../VideoAdsPage/FeatureItem/FeatureItem.module.scss";

interface LaunchProps {
  title?: string;
  description?: string;
  features?: any;
  cta?: any;
  image?: any;
}

const calc = (x, y) => [
  x - window?.innerWidth / 2,
  y - window?.innerHeight / 2
];
const videoTranslate = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`;

const LaunchSection: React.FC<LaunchProps> = props => {
  const { title, description, features, cta, image } = props;
  // Animations
  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const creative = (
    <div className={styles.creative}>
      <div className={styles.amazonPage}>
        <GatsbyImage image={image} alt="" />
      </div>
      <animated.div
        className={styles.videoContainer}
        style={{ transform: springProps.xy.interpolate(videoTranslate) }}
      >
        <video
          id={"frostier-video-player-2"}
          muted
          controls={false}
          loop
          preload="metadata"
          playsInline
          autoPlay
          crossOrigin="anonymous"
          poster={FrostierThumbnail}
        >
          <source src={Frostier} type="video/mp4" />
          <img src={FrostierThumbnail} alt="frostier-template" />
        </video>
      </animated.div>
    </div>
  );

  const launchFeaturesList = (
    <div className={styles.launchFeatureContainer}>
      {features?.map(feature => {
        return (
          <div className={featurestyles?.featureItem}>
            <div className={featurestyles?.featureItemIconCircle}>
              <img width="18" height="18" src={feature?.svg?.url} alt="" />
            </div>
            <div className={featurestyles?.featureItemText}>
              {feature?.text}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <section
      role="presentation"
      className={styles.launchSection}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: isBrowser() && calc(x, y) })
      }
    >
      <FeatureSplitContainer
        buttonId="features-video-launch"
        title={title}
        body={description}
        creative={creative}
        customBody={launchFeaturesList}
        ctaText={cta?.text}
        ctaOnClick={navigateToSignupFromSBv}
      />
    </section>
  );
};

export default LaunchSection;
