import classNames from "classnames/bind";
import React from "react";
import LeftPlant from "../../../../assets/video-ads-page/left-plant.svg";
import RightPlant from "../../../../assets/video-ads-page/right-plant.svg";
import SmallPlant from "../../../../assets/video-ads-page/small-plant.svg";
import Button, { ButtonSize, ButtonVariant } from "../../../Button";
import * as styles from "./CtaCard.module.scss";

const cx = classNames.bind(styles);

export const CtaCardType = {
  primary: "primary",
  secondary: "secondary"
};

interface CtaCardProps {
  type: string;
  title: string;
  description: string;
  buttonCta: string;
  buttonId: string;
  onClick?: () => void;
}

const CtaCard: React.FC<CtaCardProps> = props => {
  const { buttonId, type, title, description, buttonCta, onClick } = props;
  return (
    <div
      className={cx(styles.ctaCard, {
        primary: type === CtaCardType.primary
      })}
    >
      <h4>{title}</h4>
      <p>{description}</p>
      <Button
        id={buttonId}
        className={styles.ctaButton}
        variant={ButtonVariant.secondary}
        size={ButtonSize.large}
        text={buttonCta}
        onClick={onClick}
      />
      {type === CtaCardType.primary && (
        <>
          <LeftPlant className={cx(styles.plant, "left")} />
          <RightPlant className={cx(styles.plant, "right")} />
          <SmallPlant className={cx(styles.plant, "right", "small")} />
        </>
      )}
    </div>
  );
};

export default CtaCard;
