import PropTypes from "prop-types";
import React from "react";
import * as styles from "./FloatingIcon.module.scss";

const FloatingIcon = props => {
  const { icon, top, left, right, bottom } = props;
  return (
    <div className={styles.floatingIcon} style={{ top, left, right, bottom }}>
      {icon}
    </div>
  );
};

export default FloatingIcon;

FloatingIcon.prototype = {
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  icon: PropTypes.element
};
